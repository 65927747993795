<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"></dynamic-zone-renderer>
    <div>
      <div v-for="channel in filteredChannels" :key="channel.channel">

        <name-linking-dynamic-zone-renderer
            v-if="channel && channelsContent[channel.channel]"
            :title="channel.name"
            :channel="channel"
            :components="channelsContent[channel.channel].components"
            :planets="chart?.chart?.planets || []"
            :names="[chart.meta.person1.name, chart.meta.person2.name]"
        />

      </div>
      <div v-if="filteredChannels.length === 0 && !fetching" class="font-500">
        There are no electromagnetic connections in this chart.
      </div>

      <slot name="unlock-prompt"/>
      <ProgressChecker class="pt-3" type="companionship" id="2" :has-prev="hasPrev" :has-next="hasNext"
                       :order="order"
                       @prevSection="$emit('prevSection')"
                       @nextSection="$emit('nextSection')"/>
    </div>
  </div>

</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import ChannelsSetupMixin from "../../../mixins/ChannelsSetupMixin";
import channelCompositionStates from "../../../helpers/rave/constants/channelCompositionStates";
import Vue from "vue";
import NameLinkingDynamicZoneRenderer from "../../renderer/NameLinkingDynamicZoneRenderer.vue";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";

export default{
  mixins: [AnalysisQueryMixin, ChannelsSetupMixin],
  components: {ProgressChecker, NameLinkingDynamicZoneRenderer, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      channelsContent: {}
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    filteredChannels(){
      return this.channels.filter(x => x.state === channelCompositionStates.ELECTROMAGNETIC);
    }
  },
  created(){
    this.getIntroduction();
    this.setupChannelContent();
    this.getChannelContent();
  },

  methods:{
    getIntroduction(){
      let url = '/analysis/introductions/introduction.electromagnetic'
      this.getContent('content', url);
    },
    getChannelContent(){
      for(let channel of this.filteredChannels){
        Vue.set(this.channelsContent, channel.channel, {});
        let url = '/electromagnetics/' + channel.channel;
        this.getContent(('channelsContent.'+channel.channel), url, true);
      }
    }
  }
}
</script>