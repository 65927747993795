<template>
  <div class="partnership-foundational-property">
    <dynamic-zone-renderer
        v-if="intro" :components="intro.components" :add-paddings="false"
        :title="intro.name" is-toggleable toggled-when-rendered is-reverse-color />

    <dynamic-zone-renderer v-if="formattedAnalysisContent" :components="formattedAnalysisContent.components"
                           :add-paddings="false"
    />
  </div>
</template>

<script>
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import authorities from "@/helpers/rave/constants/authorities";

export default {
  components: {DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],
  props: {
    chart: Object,
    variation: {
      type: String,
      required: true,
      validator(value) {
        return ['type', 'authority', 'definition', 'profile'].includes(value)
      }
    }
  },

  data(){
    return {
      intro: null,
      analysis: null,
      // assign proper charts here.
      chartA: null,
      chartB: null,
      analysisKey: '',
    }
  },

  computed:{
    introKey() {
      return 'partnership-analysis.' + this.variation
    },
    formattedAnalysisContent() {
      const analysis = this.analysis;
      if (!analysis) return null;

      for (let cmp of analysis.components) {
        if (cmp.__component === 'dropzone.dynamic-text') {
          cmp.content = cmp.content.replaceAll(
              '[a]',
              `<span class="font-weight-bold ${
                  this.chartA.isLeft ? 'text-connection-left' : 'text-connection-right'
              }">${this.chartA.name}</span>`).replaceAll(
              '[b]',
              `<span class="font-weight-bold ${
                  this.chartB.isLeft ? 'text-connection-left' : 'text-connection-right'
              }">${this.chartB.name}</span>`);

        }
      }
      return analysis;
    }
  },

  created() {
    this.getContent('intro', `/analysis/introductions/${this.introKey}`);
    this.initializeData();
    this.getContent('analysis', `/partnership-a-b/${this.analysisKey}`);
  },

  methods: {
    initializeData() {
      const person1 = this.chart?.meta?.person1;
      const person2 = this.chart?.meta?.person2;

      let person1Value = person1?.[this.variation];
      let person2Value = person2?.[this.variation];

      // case of `SoundingBoard` or `LunarCycle`
      if (this.variation === 'authority') {
        if (person1Value === authorities.NONE) person1Value = +(String(person1Value) + String(person1.type))
        if (person2Value === authorities.NONE) person2Value = +(String(person2Value) + String(person2.type))
      }

      let a, b;
      // is the  below correct?
      if (person2Value > person1Value) {
        this.chartA = {
          name: person1.name,
          isLeft: true,
        };
        this.chartB = {
          name: person2.name,
          isLeft: false,
        };
        a = person1Value;
        b = person2Value;
      } else {
        this.chartA = {
          name: person2.name,
          isLeft: false,
        };
        this.chartB = {
          name: person1.name,
          isLeft: true,
        };
        a = person2Value;
        b = person1Value;
      }

      this.analysisKey = `${this.variation}.${a}.${b}`;
    }
  }
}
</script>