<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="intro" :components="intro.components"
                           :title="intro.name"
                           :add-paddings="false" is-toggleable is-reverse-color />
    <div v-if="selectedCenter !== null">
      <b-button size="sm" variant="primary" class="back-btn"
                @click="selectedCenter = null">
        <span class="icon-arrow-left"></span>
      </b-button>

      <center-header-nav :center="activeCenter" :prev="prev" :next="next" :center-id="selectedCenter"
                         :subtitle="$t('hd.centers.dynamics.' + activeCenter?.dynamics?.type)"
                         @changeCenter="selectCenter" />

      <div v-for="item in [...typeIntroduction, ...(centersContent[selectedCenter] || [])]" :key="item.id" class="mb-1">
        <mbg-accordion-item v-if="item.__component === 'dropzone.dynamic-text'"
                            :text="item.title"
                            :content="item.content"
                            html-title />
        <dynamic-zone-renderer v-else :components="item" :add-paddings="false" />
      </div>
    </div>
    <div v-if="selectedCenter === null">
      <center-item-selector v-for="center in centers"
                            :key="center.id"
                            :center="center"
                            @highlightElement="(ctr) => highlightElement({
                              type: types.center,
                              id: ctr.highlightElem,
                              includePlanets: true,
                              chartPlanets: chart.chart.planets,
                              isConnectionChart: true,
                            })"
                            @clearAllHighlights="clearAllHighlights"
                            @select="selectCenter"
                            :label="$t('hd.centers.dynamics.' + center?.dynamics?.type)"
      />
    </div>
    <div>
      <slot name="unlock-prompt"/>
      <ProgressChecker class="pt-3" type="companionship" id="8" :has-prev="hasPrev" :has-next="hasNext"
                       :order="order"
                       @prevSection="$emit('prevSection')"
                       @nextSection="$emit('nextSection')"/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import ChannelsSetupMixin from "../../../mixins/ChannelsSetupMixin";
import channelCompositionStates from "../../../helpers/rave/constants/channelCompositionStates";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";
import CenterMixin from "@/mixins/CenterMixin";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import CenterItemSelector from "@/components/charts/analysis-connection/partials/CenterItemSelector.vue";
import CenterHeaderNav from "@/components/charts/analysis-connection/partials/CenterHeaderNav.vue";
import MbgAccordionItem from "@/components/elements/MbgAccordionItem.vue";

function splitAtFirstH2(str) {
  const index = str.indexOf("</h2>");
  if (index === -1) return [str, ""]; // If not found, return the whole string as the first part

  return [str.substring(0, index + 5), str.substring(index + 5)]; // Include the "</h2>" in the first part
}



export default{
  mixins: [AnalysisQueryMixin, ChannelsSetupMixin, CenterMixin, HighlightingMixin],
  components: {MbgAccordionItem, CenterHeaderNav, CenterItemSelector, ProgressChecker, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      intro: null,
      channelsContent: {},
      selectedCenter: null,
      data: null,
      centersContent: {},
      // hold the per type intros (0,1,2,3)
      perTypeIntros: {},
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    filteredChannels(){
      return this.channels.filter(x => x.state === channelCompositionStates.ELECTROMAGNETIC);
    },

    activeCenter() {
      return this.centers.find(x => x.id === this.selectedCenter);
    },

    prev() {
      if (this.selectedCenter === null) return null;
      const index = this.centers.findIndex(x => x.id === this.selectedCenter);
      if (index === 0) return null;
      return this.centers[index - 1];
    },
    next(){
      if (this.selectedCenter === null) return null;
      const index = this.centers.findIndex(x => x.id === this.selectedCenter);
      if (index === this.centers.length - 1) return null;
      return this.centers[index + 1];
    },

    typeIntroduction() {
      return this.perTypeIntros[this.activeCenter.dynamics?.type]?.components.map(x => {
        const [title, content] = splitAtFirstH2(x.content);
        return {
          ...x,
          title: title.replaceAll('<h2>', '').replaceAll('</h2>', ''),
          content,
        };
      }) || [];
    }
  },
  created() {
    this.getContent('intro', '/analysis/introductions/partnership-analysis.center-dynamics');
    this.setupCenters();
    this.determineCenterDynamics();
    this.getAllContent();
    this.getIntros();
  },

  methods: {
    selectCenter(id) {
      this.selectedCenter = id;
      this.clearAllHighlights();
    },

    getIntros() {
      const intros = {
        [this.CenterDynamics.Combo]: '/analysis/introductions/partnership-analysis.defined-undefined-centers',
        [this.CenterDynamics.UndefinedToDefined]: '/analysis/introductions/partnership-analysis.mutually-defined-centers',
        [this.CenterDynamics.UndefinedToUndefined]: '/analysis/introductions/partnership-analysis.shared-undefined-centers',
        [this.CenterDynamics.Defined]: '/analysis/introductions/partnership-analysis.shared-defined-centers',
      };

      Promise.allSettled(Object.values(intros).map(x => this.getContent('data', x)))
          .then(data => {
            for (let i=0; i< Object.keys(intros).length; i++) {
              if (data[i].status === 'rejected') continue;
              Vue.set(this.perTypeIntros, Object.keys(intros)[i], data[i].value);
            }
          }).catch(()=> {});
    },
    getAllContent() {
      Promise.allSettled(this.centers.map(x => this.getContent('data', `/partnership-a-b/centers.${x.id}.${x.dynamics?.type}`)))
          .then(data =>{
            for (let i=0; i< this.centers.length; i++) {
              if (data[i].status === 'rejected') continue;
              const comps = data[i].value;
              if (!comps) continue;

              for (const comp of comps.components) {
                if (comp.__component === 'dropzone.dynamic-text') {
                  let [title, content] = splitAtFirstH2(comp.content);
                  title = title.replaceAll('<h2>', '').replaceAll('</h2>', '');

                  // replace [a] and [b] appropriately.
                  comp.title = title.replaceAll(
                      '[a]',
                      `<span class="font-weight-bold ${
                          this.centers[i].dynamics?.a?.isLeft ? 'text-connection-left' : 'text-connection-right'
                      }">${this.centers[i].dynamics?.a?.name}</span>`).replaceAll(
                      '[b]',
                      `<span class="font-weight-bold ${
                          this.centers[i].dynamics?.b?.isLeft ? 'text-connection-left' : 'text-connection-right'
                      }">${this.centers[i].dynamics?.b?.name}</span>`
                  )

                  comp.content = content.replaceAll(
                      '[a]',
                      `<span class="font-weight-bold ${
                          this.centers[i].dynamics?.a?.isLeft ? 'text-connection-left' : 'text-connection-right'
                      }">${this.centers[i].dynamics?.a?.name}</span>`).replaceAll(
                      '[b]',
                      `<span class="font-weight-bold ${
                          this.centers[i].dynamics?.b?.isLeft ? 'text-connection-left' : 'text-connection-right'
                      }">${this.centers[i].dynamics?.b?.name}</span>`
                  )
                }
              }
              this.centersContent[this.centers[i].id] = comps.components;
            }
      })
    }
  }
}
</script>