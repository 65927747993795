const SOLAR_PLEX = 0
const SACRAL = 1
const SPLENIC = 2
const EGO = 3
const G = 4
const EGO_PROJECTED = 5
const NONE = 6

const SOUNDING_BOARD = 7
const LUNAR_CYCLE = 8

export const AuthoritiesFilterAttributes = {
    SOLAR_PLEX, SACRAL, SPLENIC, EGO, G, EGO_PROJECTED,
    SOUNDING_BOARD, LUNAR_CYCLE
}

export default { SOLAR_PLEX, SACRAL, SPLENIC, EGO, G, EGO_PROJECTED, NONE }
