var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4"},[(_vm.intro)?_c('dynamic-zone-renderer',{attrs:{"components":_vm.intro.components,"title":_vm.intro.name,"add-paddings":false,"is-toggleable":"","is-reverse-color":""}}):_vm._e(),(_vm.selectedCenter !== null)?_c('div',[_c('b-button',{staticClass:"back-btn",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){_vm.selectedCenter = null}}},[_c('span',{staticClass:"icon-arrow-left"})]),_c('center-header-nav',{attrs:{"center":_vm.activeCenter,"prev":_vm.prev,"next":_vm.next,"center-id":_vm.selectedCenter,"subtitle":_vm.$t('hd.centers.dynamics.' + _vm.activeCenter?.dynamics?.type)},on:{"changeCenter":_vm.selectCenter}}),_vm._l(([..._vm.typeIntroduction, ...(_vm.centersContent[_vm.selectedCenter] || [])]),function(item){return _c('div',{key:item.id,staticClass:"mb-1"},[(item.__component === 'dropzone.dynamic-text')?_c('mbg-accordion-item',{attrs:{"text":item.title,"content":item.content,"html-title":""}}):_c('dynamic-zone-renderer',{attrs:{"components":item,"add-paddings":false}})],1)})],2):_vm._e(),(_vm.selectedCenter === null)?_c('div',_vm._l((_vm.centers),function(center){return _c('center-item-selector',{key:center.id,attrs:{"center":center,"label":_vm.$t('hd.centers.dynamics.' + center?.dynamics?.type)},on:{"highlightElement":(ctr) => _vm.highlightElement({
                            type: _vm.types.center,
                            id: ctr.highlightElem,
                            includePlanets: true,
                            chartPlanets: _vm.chart.chart.planets,
                            isConnectionChart: true,
                          }),"clearAllHighlights":_vm.clearAllHighlights,"select":_vm.selectCenter}})}),1):_vm._e(),_c('div',[_vm._t("unlock-prompt"),_c('ProgressChecker',{staticClass:"pt-3",attrs:{"type":"companionship","id":"8","has-prev":_vm.hasPrev,"has-next":_vm.hasNext,"order":_vm.order},on:{"prevSection":function($event){return _vm.$emit('prevSection')},"nextSection":function($event){return _vm.$emit('nextSection')}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }