import centerConstants from "@/helpers/rave/constants/centers";
import CenterDefinitionStates from "@/helpers/rave/constants/centerDefinitionStates";

const CenterDynamics = {
    Combo: 0,
    UndefinedToDefined: 1,
    UndefinedToUndefined: 2,
    Defined: 3,
}

export default {
    data(){
        return {
            centers: [],
            CenterDynamics
        }
    },

    methods: {
        setupCenters(){
            let centers = []
            for(const [centerId, state] of this.chart.chart.centers.entries()){
                let centerObj = centerConstants.getById(centerId);

                let center = {
                    id: centerObj.id,
                    name: this.$t('hd.centers.'+ centerObj.id + '.name') + ' ' + this.$t('hd.objects.center'),
                    state,
                    gates: centerObj.gates,
                    activeGates: this.chart.chart.gates.filter(x => centerObj.gates.includes(x.gate)).map(x => x.gate),
                    icon: centerObj.icon,
                    highlightElem: centerObj.name.toLowerCase(),
                    order: centerObj.order,
                };

                centers.push(center)
            }
            this.centers = centerConstants.sortCenters(centers);
        },

        determineCenterDynamics() {
            if (this.chart.meta.type !== 'CONNECTION_CHART') return;

            const chart1 = this.chart.meta.person1;
            const chart2 = this.chart.meta.person2;

            for (const center of this.centers) {
                const chartState = this.chart.chart.centers[center.id];
                const ch1 = chart1.centers[center.id];
                const ch2 = chart2.centers[center.id];

                // Defined + Defined.
                if (ch1 === CenterDefinitionStates.DEFINED && ch2 === CenterDefinitionStates.DEFINED) {
                    center.dynamics = {
                        type: CenterDynamics.Defined,
                        a: { name: chart1.name, isLeft: true },
                        b: { name: chart2.name, isLeft: false }
                    }
                }
                // Undefined + Undefined = (Defined | Undefined) (both cases taken in account).
                else if (ch1 !== CenterDefinitionStates.DEFINED && ch2 !== CenterDefinitionStates.DEFINED) {
                    center.dynamics = {
                        type: chartState === CenterDefinitionStates.DEFINED ? CenterDynamics.UndefinedToDefined : CenterDynamics.UndefinedToUndefined,
                        a: { name: chart1.name, isLeft: true },
                        b: { name: chart2.name, isLeft: false }
                    }
                } else if (
                    (ch1 === CenterDefinitionStates.DEFINED && ch2 !== CenterDefinitionStates.DEFINED)
                ) {
                    center.dynamics = {
                        type: CenterDynamics.Combo,
                        a: { name: chart1.name, isLeft: true },
                        b: { name: chart2.name, isLeft: false }
                    }
                } else if (
                    (ch1 !== CenterDefinitionStates.DEFINED && ch2 === CenterDefinitionStates.DEFINED)
                ) {
                    center.dynamics = {
                        type: CenterDynamics.Combo,
                        a: { name: chart2.name, isLeft: false },
                        b: { name: chart1.name, isLeft: true }
                    }
                }
            }
        }
    }
}