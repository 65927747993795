<template>
  <div id="gates-of-love" class="px-4">
    <inner-tabs-renderer :id="6"
                         type="companionship"
                         :order="order"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer.vue";
import {getElectromagneticGates} from "@/helpers/rave/constants/gatesOfLove";
import InnerTabsRenderer from "@/components/renderer/InnerTabsRenderer.vue";
import GatesOfLoveRenderer from "@/components/charts/analysis-connection/partials/GatesOfLoveRenderer.vue";

export default {
  components: {InnerTabsRenderer},
  mixins: [AnalysisQueryMixin],
  data() {
    return {
      tab: 0,
      audioKey: 0,
      intro: null,
      transcendenceIntro: null,
      personalIntro: null,
      chartA: [],
      chartB: [],
      general: [],
      gatesOfLoveContent: {}
    }
  },
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.intro,
          component: DynamicZoneRenderer,
          props: {
            components: this.intro ? this.intro.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: this.$t('charts.analysis.connection.gatesOfLove.transcendence'),
          shouldRender: !!this.transcendenceIntro,
          component: GatesOfLoveRenderer,
          props: {
            intro: this.transcendenceIntro,
            chartAName: this.chart.meta.person1.name,
            chartBName: this.chart.meta.person2.name,
            chartA: this.chartA.transcendent,
            chartB: this.chartB.transcendent,
            general: this.general,
            isPersonal: false,
          }
        },
        {
          id: 2,
          title: this.$t('charts.analysis.connection.gatesOfLove.personal'),
          shouldRender: !!this.personalIntro,
          component: GatesOfLoveRenderer,
          props: {
            intro: this.personalIntro,
            chartAName: this.chart.meta.person1.name,
            chartBName: this.chart.meta.person2.name,
            chartA: this.chartA.personal,
            chartB: this.chartB.personal,
            general: this.general,
            isPersonal: true,
          }
        }
      ]
    }
  },
  created() {
    this.parseGatesOfLove();
    this.getContent('intro', '/analysis/introductions/introduction.love');
    this.getContent('transcendenceIntro', '/analysis/introductions/introduction.love.transcendent');
    this.getContent('personalIntro', '/analysis/introductions/introduction.love.personal');
    this.fetchGateContent();
  },
  methods: {
    parseGatesOfLove() {
      const personOnePlanets = this.chart.chart.planets.filter(x => x.chartId === 0);
      const personTwoPlanets = this.chart.chart.planets.filter(x => x.chartId === 1);

      this.chartA = getElectromagneticGates(personOnePlanets, personTwoPlanets, this.chart.chart);
      this.chartB = getElectromagneticGates(personTwoPlanets, personOnePlanets, this.chart.chart);
    },

    fetchLoveGate(gate) {
      this.getArrayContent('general', `/gates-of-love/${gate}`);
    },

    fetchGateContent() {
      let uniqueContent = new Set();
      for (let gate of [...this.chartA.personal, ...this.chartA.transcendent, ...this.chartB.personal, ...this.chartB.transcendent]) {
        uniqueContent.add(gate.gate + '.' + (gate.isPersonal ? 'personal' : 'transcendence'));
      }

      for (let gate of [...uniqueContent]) {
        this.fetchLoveGate(gate)
      }
    }
  }
}
</script>