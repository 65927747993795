<template>
  <div @click="$emit('select', center.id)"
     @mouseover="$emit('highlightElement', center)"
     @mouseleave="$emit('clearAllHighlights')" class="highlight-trigger">
  <b-card class="my-2 cursor-pointer border-primary">
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <span :class="`icon-center-${center.icon}`"></span>
      </div>
      <div>
        <label v-if="label" class="font-weight-light">{{label}}</label>
        <h6>
          {{center.name}}
        </h6>
      </div>
      <div class="ml-auto">
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </b-card>
</div>
</template>
<script>
export default {
  props: {
    center: Object,
    types: Object,
    label: String,
  }
}
</script>