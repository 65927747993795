<template>
  <div id="gates-of-love" class="px-4">
    <inner-tabs-renderer :id="7"
                         type="companionship"
                         :order="order"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import InnerTabsRenderer from "@/components/renderer/InnerTabsRenderer.vue";
import FoundationalProperty from "@/components/charts/analysis-connection/partials/FoundationalProperty.vue";

export default {
  props: {
    order: Number
  },
  components: {InnerTabsRenderer},
  mixins: [AnalysisQueryMixin],
  data() {
    return {
      tab: 0,
      audioKey: 0,
      intro: null,
      transcendenceIntro: null,
      personalIntro: null,
      chartA: [],
      chartB: [],
      general: [],
      gatesOfLoveContent: {}
    }
  },
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('charts.analysis.connection.foundational.typeToType'),
          component: FoundationalProperty,
          props: {
            chart: this.chart,
            variation: 'type',
          },
          shouldRender: true,
        },
        {
          id: 1,
          title: this.$t('charts.analysis.connection.foundational.authorityToAuthority'),
          component: FoundationalProperty,
          props: {
            chart: this.chart,
            variation: 'authority',
          },
          shouldRender: true,
        },
        {
          id: 2,
          title: this.$t('charts.analysis.connection.foundational.definitionToDefinition'),
          component: FoundationalProperty,
          props: {
            chart: this.chart,
            variation: 'definition',
          },
          shouldRender: true,
        },
        {
          id: 3,
          title: this.$t('charts.analysis.connection.foundational.profileToProfile'),
          component: FoundationalProperty,
          props: {
            chart: this.chart,
            variation: 'profile',
          },
          shouldRender: true,
        },
      ]
    }
  }
}
</script>