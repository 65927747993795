<template>
  <div v-show="isChartPage && notifications.length" id="transit-notifications">

    <div class="controls text-right mb-3">
      <b-btn v-if="notifications.length > 0"
             variant="full-white"
             class="notifications-toggle"
             @click="clearNotifications">
        <span class="icon-close"/>
        <span class="show-on-hover">
          Clear all
        </span>
      </b-btn>
    </div>
    <div>
      <b-card v-for="item in notifications" :key="item.time" class="casual-border-radius notification-item mb-2">
        <div class="d-flex flex-wrap gap-5">
          <h5 class="d-flex align-items-center gap-5">
            <span :class="`icon-${planets.icons[item.planet]}`"/>
            {{$t('hd.planets.' + item.planet + '.name')}} has shifted
          </h5>

          <b-btn variant="link" size="sm" class="ml-auto" @click="$store.commit('charts/CLEAR_NOTIFICATION_BY_DATE_TIME', item)"><span class="icon-close"/></b-btn>
        </div>
        <div>
          <div class="mb-2">
            <p class="mb-2">
              <strong>{{$t('hd.properties.lines.' + item.meta.line)}}</strong>
            </p>
            <div class=" ">
              <span v-html="getIchingIcon(item.meta.gate)"/>
              {{item.meta.gate}} -
              {{$t('hd.gates.'+ item.meta.gate + '.name') }} - {{$t('hd.gates.'+ item.meta.gate + '.titleKeynote') }}
            </div>
          </div>
          <div class="mbg-text-muted">
            {{formatDate(item.meta.datetime)}}
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import planets from "../../../helpers/rave/constants/planets";
import {getIchingIcon} from "../../../utils/iching";


export default{
  computed:{
    ...mapState({
      notifications: state => state.charts.transitNotifications
    }),

    isChartPage(){
      return this.$route.path.includes('charts/')
    }
  },
  watch:{
    isChartPage(){
      this.clearNotifications();
    }
  },
  data(){
    return{
      planets
    }
  },

  methods:{
    getIchingIcon,
    clearNotifications(){
      this.$store.commit('charts/CLEAR_TRANSIT_NOTIFICATIONS');
    }
  }
}

</script>

<style lang="scss">

#transit-notifications {
  z-index:99999;
  position: fixed;
  top: 50px;
  right: 30px;
  width: 350px;
  .show-on-hover{
    display:none;
  }

  .notifications-toggle{
    opacity:1!important;
    box-shadow: 0 5px 50px 5px rgba(0,0,0,.4)!important;
    width: 60px;
    &:hover{
      .show-on-hover{
        display:initial;
      }
      width:120px;
    }
  }

  .notification-item{
    box-shadow: 0 5px 50px 5px rgba(0,0,0,.1);
    border-left: 10px solid $transit-color;
  }
}
.theme--dark{
  .notification-item{
    box-shadow: 0 5px 50px 5px rgba(0,0,0,.4)!important;
  }
}
</style>